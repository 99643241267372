<template>
	<div>
		<button
			@click="is_on_support = true"
		><img :src="require('@/assets/image/banner/banner_support.png')" /></button>

		<CartelSupport
			v-if="is_on_support"
			:user="user"
			:item_cartel="item_cartel"

			@cancel="is_on_support = false"
			@click="is_on_support = false"
		></CartelSupport>
	</div>
</template>

<script>
	import CartelSupport from "@/view/Cartel/CartelSupport";
	export default {
		name: 'CartelEtcSupport'
		, components: {CartelSupport}
		, props: ['user', 'item_cartel']
		, data: function(){
			return {
				is_on_support: false
			}
		}
		, methods:{

		}
	}
</script>