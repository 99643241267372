<template>
	<div>
	<CartelSubscribeRequest
		v-if="is_on_request"
		:user="user"
		:cartel_number="cartel_number"
		:plan_number="plan_number"
		:plan_info="plan_info"
		:plan_payment_type="plan_payment_type"

		@cancel="offRequest"
	></CartelSubscribeRequest>

	<PopupLayer
		v-else
		@cancel="$emit('cancel')"
	>
		<template
		v-slot:body
		>

		<div
            class="flex-column radius-20 text-left  radius-20 overflow-hidden" 
            style="min-width: 320px; max-width: 480px; margin: 0 auto; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
        >
            <div class="bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20">
                <h4 class="color-white">{{ $language.cartel_new.title_plan_choice }}<!-- 구독 플랜 선택 --></h4>

                <img class="cursor-pointer"
                    @click="$emit('cancel')"
                    :src="require('@/assets/image/web/notify_cartel/ico_close.svg')"
                />
		</div>
		<div class="bg-white pa-30">
			<template
				v-if="list_plan.length > 0"
			>
				<ul class="flex-column gap-20 overflow-y-auto scroll-black"
					style="max-height: 410px;"
				>
					<li
						v-for="(item, index) in list_plan"
						:key="'item_' + index"
						@click="setItem(item)"
						class="justify-space-between items-center"
						:class="{ 'on-subscribe': item.subscrp_plan_number == item_payment.subscrp_plan_number }"
					>
						<div class="overflow-hidden img-box-100 mr-10 radius-10 position-relative">
							<img :src="item.img_physl_path" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/>
							<div
								v-if="item.subscrp_plan_number == item_payment.subscrp_plan_number"
								class="position-absolute-full flex-column justify-center items-center"
							>
								<img :src="require('@/assets/image/web/ico_subscribe_marked.svg')">
							</div>
						</div>
						<div class="flex-1">
							<div class="mt-10 font-weight-500 size-px-14">{{ item.subscrp_plan_name }}</div>
							<div class="mt-10 size-px-16 font-weight-600 color-blue-3e flex-row align-center gap-5">
								<img :src="require('@/assets/image/icon_won_blue.svg')" /> 
								<span class="line-height-1">
									{{ item.subscrp_amount | makeComma }}원
								</span>
							</div>
						</div>
					</li>
				</ul>

				<div class="mt-30 flex-row justify-center">
					<button
						@click="toPayment"
						:is_disabled="is_disabled"
						:is_confirm="true"
						class="bg-primary pa-13-30 color-white size-px-14 font-weight-500 radius-20 line-height-1"
					>확인</button>
				</div>
			</template>
			
			<li
				v-else
				class="character3_none pt-130 pa-50 mt-50 text-center size-px-16 font-weight-500 color-bbb"
			>선택가능한 플랜이 없습니다.</li>

			
		</div>
		</div>
	</template>
	</PopupLayer>
	</div>
</template>
<script>
// import Popup_select from "@/view/Layout/PopupSelect";
import PopupLayer from "@/view/Layout/PopupLayer";
import CartelSubscribeRequest from "@/view/SubscribeCartel/CartelSubscribeRequest";
export default {
	name: 'CartelSubscribe'
	, props: ['user', 'plan_info', 'cartel_number']
	, components: {CartelSubscribeRequest, PopupLayer}
	, data: function(){
		return {
			items: []
			, item_payment: {}
			, is_on_request: false
			, plan_number: ''
			, plan_payment_type: ''
		}
	}
	, computed: {
		list_plan: function(){
			return this.items.filter( (item) => {
				if(item.subscrp_plan_state_code == 'SS00200002'){
					return item
				}
			})
		}
		, is_disabled: function(){
			let t = true
			if(this.item_payment.subscrp_plan_number){
				t = false
			}
			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel_number
						, subscrp_plan_number: this.plan_info.subscrp_plan_number ? this.plan_info.subscrp_plan_number :this.plan_info.payment_subscrp_plan_number
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.subscrp_plan_list
					console.log(this.items_plane)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setItem: function(item){
			console.log(item)
			this.item_payment = item
		}
		, toPayment: function(){

			this.plan_number = this.item_payment.subscrp_plan_number
			this.plan_payment_type = this.plan_info.subscrp_plan_number ? this.plan_info.subscrp_plan_number: this.plan_info.payment_subscrp_plan_number
			this.is_on_request = true

			// this.$bus.$emit('to',{ name: 'CartelSubscribeRequest', params: { idx: this.$route.params.idx ? this.$route.params.idx : this.cartel_number, p_id: this.item_payment.subscrp_plan_number, type: this.plan_info.subscrp_plan_number ? this.plan_info.subscrp_plan_number: this.plan_info.payment_subscrp_plan_number}})
		}
		, offRequest: function(){
			this.is_on_request = false
		}
	}
	, created() {
		console.log('plan_info', this.plan_info)
		this.getData()
	}
}
</script>

<style>

.on-subscribe .img-box-100 {
	border: 1px solid var(--mafia-Blue)
}
</style>