<template>
	<div
		class="bg-white pa-24 radius-20 mb-30"
	>
		<div
			class="flex-row justify-space-between align-center items-center cursor-pointer"
			@click="toNotice"
		>
			<div class="flex-row gap-10">
				<img :src="require('@/assets/image/web/home_cartel/ico_noti.svg')">
			<h4 class="size-px-14">카르텔 공지사항</h4>
			</div>

			<button
				v-if="notice_board_number"
			>
				<img :src="require('@/assets/image/web/home_cartel/ico_arrow_right_sm.svg')">
			</button>
		</div>
		<div class="mt-10">
			<ul
				v-if="items_notice.length > 0"
			>
				<li
					v-for="(notice, n_index) in list_notice"
					:key="'notice_' + n_index"
					class="flex-row justify-space-between under-line-not-last pt-10 pb-10 cursor-pointer" style="align-items: baseline;"
					@click="toDetail(notice)"
				>
					<div class="size-px-14 color-333"><span class="label label-notice size-px-12">공지</span> {{  notice.post_title | maxLength(20, '...') }}</div>
					<div
						v-if="false"
						class="size-px-14 color-888"
					>{{  notice.date }}</div>
				</li>
			</ul>
			<div
				v-else
				class="none"
			>등록된 공지사항이 없습니다.</div>

			<Pagination
				v-if="false"
				:options="item_search_notice"
				@click="getSearchNoticeList"
			></Pagination>
		</div>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
	name: 'CartelEtcNotice'
	, props: ['user']
	, components: { Pagination }
	, data: function(){
		return {
			program: {
				name: 'CartelEtcNotice'
				, title: '카르텔 공지사항'
			}
			, items_notice: []
			, item_search_notice: {
				page_number: 1
				, pagerecnum: 5
				, page_cnt: 5
			}
			, notice_board_number: ''
		}
	}
	, computed: {
		list_notice: function(){
			return this.items_notice.filter((item) => {
				item.date = (item.registdate).substring(0, 10)
				return item
			})
		}
	}
	, methods: {

		getSearchNoticeList: function(page = 1){
			this.item_search_notice.page_number = page
			this.getNoticeList()
		}
		, getNoticeList: async function(){
			try {
				// this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						//, board_number: this.$route.params.b_id
						, page_number: this.item_search_notice.page_number
						, pagerecnum: this.item_search_notice.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_notice = result.data.post_list
					if(this.items_notice[0]){
						this.notice_board_number = this.items_notice[0].board_number
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item) {
			this.$log.console('item', item)
			this.$bus.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, toNotice: function(){
			this.$bus.$emit('to', { name: 'mafia053', params: {  idx: this.$route.params.idx, code: 'CA00700001', b_id: this.notice_board_number }})
		}
	}

	, created() {
		this.getNoticeList()
	}
	, watch: {

		$route(){
			this.getNoticeList()
		}
	}
}
</script>