<template>
	<div
		class="bg-white pa-24 radius-20 "
	>
		<div class="flex-row justify-space-between cursor-pointer">
			<div class="flex-row align-center gap-10">
				<img :src="require('@/assets/image/web/home_cartel/ico_drops.svg')">
				<h4 class="size-px-14">NFT DROPS</h4>
			</div>
			<div
				v-if="false"
			>
				<img
				class="cursor-pointer" 
				:src="require('@/assets/image/web/index_cartel/ico_arrow_right_lg.svg')" style="width: 8px">
			</div>
		</div>
		<div class="mt-25">
			<div class="">
				<div class="position-relative">
					<div
						class="radius-20" style="border: 1px solid rgba(0, 0, 0, 0.1); width: 222px; height: 222px; margin: 0 auto;"
					>
						<img :src="require('@/assets/image/web/nft_collection/bg_dropbox_home.jpg')"/>
					</div>
					<div
						class="position-absolute-full flex-column justify-center items-center bg-nft"
					>
						<img :src="require('@/assets/image/web/home_cartel/ico_dropbox.svg')" />
						<h4 class="mt-10 color-white size-px-16">NFT CARD</h4>
					</div>
				</div>
				<div class="mt-20 size-px-20 font-weight-bold color-mafia-blue">{{ text_unit }}{{ item_nft_info.sl_leglter_price | makeComma(8) }}</div>
				<div class="mt-10 color-888 size-px-12">랜덤 NFT카드를 구입하세요.</div>
				<div class="mt-20">
					<button
						v-if="max.nft > 0"
						class="btn btn-primary radius-20 font-weight-600"
						@click="toDrops"
					>BUY</button>
					<button
						v-else
						class="btn btn-primary radius-20 font-weight-600"
						disabled
					>SOLD OUT</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CartelEtcDrops'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'NFT DROPS'
					, title: 'NFT DROPS'
				}
				, item_nft: {
					sl_coin_token_list: [
						{}, {}
					]
				}
				, sl_coin_token_list: []
				, item_select_coin: {
					sl_coin_token_name: ''
				}
			}
		}, computed: {
			text_unit: function(){
				let t = ''
				switch((this.item_select_coin.sl_coin_token_name).toLowerCase()){
					case 'klaytn':
						t = 'KLAY'
						break
					case 'eth':
						t = 'ETH'
						break
					case 'reap':
						t = 'REAP'
						break
					case 'maf':
						t = 'MAF'
						break
					case 'Reap':
						t = 'REAP'
						break
				}
				switch (this.item_nft.sl_leglter_div_code){
					case 'BC00800001':
						t = '₩'
						break;
					case 'BC00800002':
						t = '$'
						break;
					default:
						t = '$'
						break;
				}
				return t
			}
			, item_nft_info: function(){
				let t = this.item_nft

				t.price = t.sl_coin_token_list[1].sl_coin_token_quantity
				t.sl_coin_token_code = t.sl_coin_token_list[1].sl_coin_token_code
				t.blockchain_div_code = t.sl_coin_token_list[1].blockchain_div_code
				t.sl_coin_token_name = t.sl_coin_token_list[1].sl_coin_token_name

				t.sl_coin_token_list.filter( (coin) => {
					if (coin.sl_coin_token_code == this.item_select_coin.sl_coin_token_code) {
						t.price = coin.sl_coin_token_quantity
						t.sl_coin_token_code = coin.sl_coin_token_code
						t.blockchain_div_code = coin.blockchain_div_code
						t.sl_coin_token_name = coin.sl_coin_token_name
					}
				})

				return t
			}
			, card_list: function() {
				// let t = []
				return this.items.filter((item) => {
					return item.sl_coin_token_list.filter((coin) => {
						if (coin.blockchain_div_code == this.item_select_coin.blockchain_div_code) {
							item.price = coin.sl_coin_token_quantity
							item.sl_coin_token_code = coin.sl_coin_token_code
							item.blockchain_div_code = coin.blockchain_div_code
							item.max = item.sl_quantity - item.sl_compl_quantity
						}
					})
				})
				//return t
			}
			, is_purchase: function(){
				let t = {
					nft: false
					, utility: false
				}

				if(this.item_nft.sl_state_code == 'NF01500004'){
					t.nft = true
				}

				return t
			}
			, blockchain_list: function(){
				let t = [
					{ sl_coin_token_name: 'REAP', blockchain_div_code: 'BC00100002' }
					, { sl_coin_token_name: 'KLAY', blockchain_div_code: 'BC00100001' }
				]
				if(this.card_type_code == 'NF00100005'){
					t = this.items[0].sl_coin_token_list
				}else{
					t = this.item_nft.sl_coin_token_list
				}

				return t.filter(function(item){
					switch (item.sl_coin_token_code){
						case 'BC00200001':
							item.label = 'ETH'
							break;
						case 'BC00200002':
							item.label = 'KLAY'
							break;
						case 'BC00200003':
							item.label = 'MAF'
							break;
						case 'BC00200004':
							item.label = 'MAF'
							break;
						case 'BC00200005':
							item.label = 'REAP'
							break;
					}
					return item
				})
			}
			, max: function(){
				let t = {
					union: 0
					, nft: 0
				}

				t.nft = this.item_nft.sl_quantity - this.item_nft.sl_compl_quantity

				return t
			}
		}
		, methods: {
			getData:  async function(){

				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_drops_config
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.item_nft = result.data
						this.item_select_coin = this.item_nft.sl_coin_token_list[1]
						let t = this.item_nft.sl_quantity - this.item_nft.sl_compl_quantity
						if(this.max > t){
							this.max = t
						}
						this.$log.console('this.item_nft', this.item_nft)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toDrops: function(){
				this.$bus.$emit('to', { name: 'drops'})
			}
		}
		, created() {
			this.getData()
		}
	}
</script>

<style>
	.bg-nft { background: linear-gradient(180deg, rgba(255, 255, 255, 0) 46.35%, rgba(255, 255, 255, 0.7) 100%); }
</style>