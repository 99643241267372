<template>
	<div
		class="position-relative"
        :class="{ 'box-etc' : only_nav !== 'only'}"
	>
		<div
			:class="{ '': is_top_list }"
            v-if="only_nav !== 'only'"
		>
			<CartelEtcNotice
				v-if="is_notice"
				:user="user"
			></CartelEtcNotice>

			<LiveTop
				:user="user"
				:cartel_info="cartel_info"

				@openLive="openLive"
			></LiveTop>

			<CartelEtcComment
				v-if="bbs_info && bbs_info.bulletin_number"
				:user="user"
				:bbs_info="bbs_info"
			></CartelEtcComment>

			<CartelEtcBanner
				v-if="is_banner"
				:user="user"
			></CartelEtcBanner>

			<CartelEtcSubscribe
				v-if="is_on_subscribe"
				:user="user"
				:cartel_info="cartel_info"
				:bbs_info="bbs_info"

			></CartelEtcSubscribe>

			<CartelEtcDrops
				v-if="false"
				:user="user"
			></CartelEtcDrops>

			<CartelEtcSupport
				v-if="is_cartel_support"
				:user="user"
				:item_cartel="cartel_info"
				class="mt-20"
			></CartelEtcSupport>

			<div
				class="box-navigate flex-column "
			>
				<div
					v-if="is_join"
					class="cursor-pointer bg-primary pa-20 justify-center align-center btn-write"
					style="border-radius: 100%; font-size: 13px; font-weight: 500;"
					@click="toJoin"
				>
					<span>카르텔 가입</span>
				</div>

                <button
                    v-if="is_live"
                    class="mt-1- btn-red"
                    @click="createLive"
                >
                  <img :src="require('@/assets/image/ani_icon_live.gif')" alt="라이브 채팅 개설" style="width: 30px; height: 30px;" />
                </button>

				<button
					v-if="is_write"
					class="mt-1- btn-write mt-10"
					@click="toWrite"
				>
					<img :src="require('@/assets/image/web/home_cartel/ico_write.svg')" alt="글쓰기"/>
				</button>

				<button
					v-if="is_bbs"
					@click="toBack"
					class="mt-10"
				>
					<img :src="require('@/assets/image/web/home_cartel/ico_back.svg')" alt="뒤로가기" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));"/>
				</button>

				<button
					class="mt-10"
					@click="toTop"
				>
					<img :src="require('@/assets/image/web/home_cartel/ico_top.svg')" alt="위로가기" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));"/>
				</button>
			</div>
		</div>
        <div
            class="box-navigate flex-column "
            v-else
        >
            <div
                v-if="is_join"
                class="cursor-pointer bg-primary pa-20 justify-center align-center btn-write"
                style="border-radius: 100%; font-size: 13px; font-weight: 500;"
                @click="toJoin"
            >
                <span>카르텔 가입</span>
            </div>

<!--            <button-->
<!--                v-if="is_live"-->
<!--                class="mt-1- btn-red"-->
<!--                @click="createLive"-->
<!--            >-->
<!--                <img :src="require('@/assets/image/web/home_cartel/ani_icon_live.svg')" alt="라이브 채팅 개설"/>-->
<!--            </button>-->

            <button
                v-if="is_write"
                class="mt-1- btn-write mt-10"
                @click="toWrite"
            >
                <img :src="require('@/assets/image/web/home_cartel/ico_write.svg')" alt="글쓰기"/>
            </button>

            <button
                v-if="is_bbs"
                @click="toBack"
                class="mt-10"
            >
                <img :src="require('@/assets/image/web/home_cartel/ico_back.svg')" alt="뒤로가기" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));"/>
            </button>

            <button
                class="mt-10"
                @click="toTop"
            >
                <img :src="require('@/assets/image/web/home_cartel/ico_top.svg')" alt="위로가기" style="filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));"/>
            </button>
        </div>
		<PopupConfirm
			v-if="is_to_join"

			@click="postCartelJoin"
			@cancel="is_to_join = false"
		>
			<template
				v-slot:title
			>카르텔 가입</template>
			<template
				v-slot:main-txt
			>
				<div class="">{{ cartel_info.cartl_name }}</div>
			</template>
			<template
				v-slot:sub-txt
			>
				<div cass="mt-20">{{ cartel_info.cartl_desctn }}</div>
			</template>
			<template
				v-slot:content
			>
				<div class="color-888 mt-20 size-px-12">이 카르텔에서 활동하는 동안 원활한 카르텔 운영을 위하여 아이디, 별명, 활동내역이 카르텔의 운영진에게 공개되며 카르텔 정책에 위반된 활동을 할 경우 제재를 받을 수 있습니다. 본 동의를 거부하실 수 있으나, 카르텔 가입이 불가능합니다.</div></template>
		</PopupConfirm>

		<PopupConfirm
			v-if="is_confirm"
			:not_cancel="true"
			@click="toHome"
			@cancel="toHome"
		>
			<template v-slot:title>카르텔 가입</template>
			<template
				v-if="cartel_info.entry_approval_fg == 'Y'"
				v-slot:main-txt
			>해당 카르텔은 관리자 승인이 필요합니다</template>
			<template
				v-if="cartel_info.entry_approval_fg == 'Y'"
				v-slot:sub-txt
			>
			<div style="margin-top:-10px">관리자 승인 후 카르텔 활동이 가능합니다</div>
			</template>
			<template
				v-else
				v-slot:sub-txt
			>카르텔 가입이 완료되었습니다</template>
		</PopupConfirm>
	</div>
</template>

<script>
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	import CartelEtcNotice from "@/view/Cartel/CartelEtcNotice";
	import CartelEtcComment from "@/view/Cartel/CartelEtcComment";
	import CartelEtcDrops from "@/view/Cartel/CartelEtcDrops";
	import CartelEtcBanner from "@/view/Cartel/CartelBanner";
	import CartelEtcSupport from "@/view/Cartel/CartelEtcSupport";
	import CartelEtcSubscribe from "@/view/Cartel/CartelEtcSubscribe";
	import LiveTop from "@/view/LiveChat/LiveTop";

	export default {
		name: 'CartelEtc'
		, props: ['user', 'cartel_info', 'bbs_info', 'only_nav']
		, components: {
			LiveTop,
			CartelEtcSubscribe,
			CartelEtcSupport,
			CartelEtcBanner, CartelEtcDrops, CartelEtcComment, CartelEtcNotice, PopupConfirm}
		, data: function() {
			return {
				rows: 1
				, upload_img: ''
				, scrollTop: 0
				, is_scroll: false
				, h: 0
				, is_to_join: false
				, is_to_write: false
				, is_confirm: false
				, is_062: false
				, is_0632: false
				, upper_index: 0
				, is_top_list: false
			}
		}
		, computed: {
			is_notice: function(){
				let t = false
				if(this.$route.params.idx && (this.$route.params.code == 'CA00700001' || (this.$route.params.code != 'CA00700001' && !this.$route.params.bbs_id))){
					t = true
				}
				return t
			}
			, is_write: function(){
				let t = false
				if(this.cartel_info?.cartl_entry_state_code == 'CA00300004'){
					t = true
				}
				return t
			}
            , is_live: function(){
				let t = false
				if(this.cartel_info?.cartl_member_grade_chg_info.chg_cartl_member_div_code === 'CA02500001'){
					t = true
				}

				return t
			}
			, is_join: function(){
				let t = false
				if(this.cartel_info?.cartl_entry_state_code == ''){
					t = true
				}
				return t
			}
			, is_join_confirm: function(){
				let t = false
				switch(this.cartel_info.cartl_entry_state_code) {
					case 'CA00300004':
						t = true
						break
				}
				return t
			}
			, is_join_cancel: function (){
				let t = false
				switch(this.cartel_info.cartl_entry_state_code){
					case 'CA00300003':
					case 'CA00300005':
					case 'CA00300006':
					case 'CA00300007':
						t = true
						break;
				}
				return t
			}
			, is_join_wait: function(){
				let t = false
				switch(this.cartel_info.cartl_entry_state_code){
					case 'CA00300001':
					case 'CA00300002':
						t = true
						break;
				}
				return t
			}
			, is_bbs: function(){
				let t = false
				if(this.$route.params.bbs_id){
					t = true
				}
				return t
			}
			, is_drops: function(){
				let t = false
				if(!this.bbs_info || !this.bbs_info.bulletin_number){
					t = true
				}
				return t
			}
			, is_banner: function(){
				let t = false
				if(!this.$route.params.bbs_id){
					t = true
				}
				return t
			}
			, is_cartel_support: function(){
				let t = false
				if(this.$route.params.idx && !this.is_subscribe_manager){
					t = true
				}
				return t
			}
			, is_subscribe_manager: function(){
				let t = false
				if(this.cartel_info.cartl_member_grade_chg_info.chg_cartl_member_div_code == 'CA02500001'){
					t = true
				}
				return t
			}
			, is_subscribe: function(){
				let t = false
				if(this.cartel_info.cartl_member_grade_chg_info.member_cartl_subscrp_fg == 'Y'){
					t = true
				}
				return t
			}
			, subscribe_last: function(){
				let t = ''
				let now = new Date()
				let last = new Date(this.cartel_info.cartl_member_grade_chg_info.member_subscrp_enddate)
				last = new Date('2023-01-01')

				let diff = Math.abs(now.getTime() - last.getTime());
				t = Math.ceil(diff / (1000 * 60 * 60 * 24));

				return t
			}
			, is_on_subscribe: function(){
				let t = false
				if(this.$route.params.idx){
					t = true
				}
				return t
			}
		}
		, methods: {
            createLive(){
                this.$emit('createLive')
            }
			, toJoin: function(){
				this.is_to_join = true
			}
			, toWrite: function(){
				this.$bus.$emit('to', {name: 'mafia1271', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id}})
			}

			,postCartelJoin: async function(){
				try{
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_join
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.is_confirm = true
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_to_join = false
				}
			}
			, toHome: function(){
				document.location.reload()

				this.$bus.$emit('to', { name: 'mafia049', path: '/cartel/mafia049/' + this.$route.params.idx, params: {idx: this.$route.params.idx }})
				this.is_confirm = false
			}
			, toDrops: function(){
				this.$bus.$emit('to', { name: 'drops'})
			}
			, mount: function(){
			}

			// 2023.05.15 탑버튼 기능 추가 - 카단
			, toTop: function(){
				window.scrollTo({
					top:0,
					behavior: 'smooth',
				});
			}
			, toBack: function(){
				this.$router.go(-1);
			}

			, openLive() {
				this.$emit('openLive')
			}
		}
		, created() {
			// console.log('CartelEtc cartel_info', this.cartel_info)
			this.$log.console('this.bbs_info', this.bbs_info)

			this.$log.console(this.$route.params)
			this.mount()

			window.addEventListener('scroll', () => {

				let scrollTop = window.scrollY
				// this.$log.console('let scrollTop', scrollTop)
				if(scrollTop >= 381){
					this.is_top_list = true
				}else{
					this.is_top_list = false
				}

			})
		}
		, watch: {

			$route(to, from){
				this.mount()
				this.$log.console(to, from)
			}
		}
	}
</script>

<style>
.btn-red {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #DC505C;
  transition: .3s all;
  position: relative;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
}

.btn-red img{
  transition: .3s all;
}
.btn-red:hover{
  background: #d02a38;
}
.btn-red:hover img{
  animation: write-scale .5s forwards;
}
	.wrap-comment::-webkit-scrollbar{
		width: 2px;
		background-color: #333;
	}
	.wrap-comment::-webkit-scrollbar-thumb {
		background-color: #2f3542;
	}
	.wrap-comment::-webkit-scrollbar-track {
		background-color: #bbb;
	}

	.comment-box::-webkit-scrollbar{
		width: 0px;
	}

	.post_comment_main_view { padding: 10px 0; border-bottom: 1px solid var(--light-Gray01); }
	.wrap-comment li:last-child .post_comment_main_view { border-bottom: none}
	.post_comment_main_view .comment_layout + .comment_layout { border-bottom: 1px solid var(--light-Gray01); }
	.comment_count { display: block; font-size: 16px; color: var(--dark-Gray02); font-weight: 500; }
	.comment_count .count { font-size: inherit; color: inherit; font-weight: 600; }


	.comment_layout { position: relative; padding: 0; }
	.item_wrap { position: relative; padding: 0 0 0 4.2rem; }
	.item_wrap .write_user .u_profile { position: absolute; left: 0; top: 0; }
	.item_wrap .write_user .write_desc { display: flex; justify-content: space-between; }
	.item_wrap .write_user .write_desc .name { display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 14px; line-height: 3.2rem; font-weight: 500; color: var(--dark-Gray02); }
	/* .item_wrap .write_info .write_desc .time { display: block; margin-left: 1rem; flex: 1; min-width: 5rem; line-height: 3.2rem; font-size: 1.4rem; color: var(--gray02); } */
	.item_wrap .comment_body { font-size: 0;}
	.item_wrap .comment_body p { display: inline-block; font-size: 13px; line-height: 14px; color: var(--dark-Gray02); }
	.item_wrap .write_info { margin-top: 0.8rem; color: var(--gray02); font-size: 0; }
	.item_wrap .write_info .time { display: inline-block; margin-right: 1rem; font-size: 13px; line-height: 1; vertical-align: top; }
	.item_wrap .write_info .reply { display: inline-block; font-size: 13px; line-height: 1; color: var(--gray02);  vertical-align: top; }
	.hashtag { display: inline-block; margin-right: 1rem; font-size: 13px; line-height: 14px; color: var(--blue01); font-weight: 500; vertical-align : top;}

	.replylist_view { padding: 0 0 0 4.2rem; }
	.replylist_view .item_wrap {  }

	/* min-height: 5rem; */
	.comment_write { filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.05))}

	.photo_area { position: relative; }
	.photo_area::before { content: ''; position: absolute; top:0; left: 0; background: black; opacity: 0.3; }
	.photo_area .photo_area_view { position: relative; padding: 0; height: 200px; max-width: 100%; margin: 0 auto; }
	.photo_area .photo_area_view img { height: 100%; width: 100%; object-fit: contain; }

	.view_close { position: absolute; right: 10px; top: 10px; background-color: #bbb; padding: 0px 5px 3px}
	.view_close i { font-size: 1.2px; color: #fff; }

	.write_area { background-color: #fff; padding: 10px}

	.write_main { display: flex; align-items: flex-end; }

	.btn_upload { flex-shrink: 1; position: relative; display: block; width: 5rem; min-height: 5rem; }
	.comment_albumfile:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 24px; height: 24px; background: url(../../assets/image/ico_camera.png) no-repeat center center / 24px auto;}

	.mentions { flex: 1; margin: 1.5rem 0; height: auto; }
	.mentions .hashtag { line-height: 2.4rem;}
	.mentions textarea { line-height: 20px; width: 100%; font-size: 16px }
	.mentions textarea::placeholder { color: var(--gray01); }

	.submit_wrap .write_submit { flex-shrink: 1; position: relative; display: block;  width: 5rem; min-height: 5rem;}
	.write_submit:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 24px; height: 24px; background: url(../../assets/image/ico_submit.png) no-repeat center center / 24px auto; }

	.hide{ display: none}

	.wrap-comment .on { background-color: #eee}

	.box-navigate {
		position: fixed; right: 13%; bottom: 30px; z-index: 999
	}

</style>