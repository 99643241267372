<template>
	<div
		class="bg-white radius-20 text-left flex-column mb-30"
	>
		<div
			v-if="mode == 'comment'"
			class="pt-20 flex-row gap-10" style="padding-left: 24px; padding-right:24px"
		>
			<img :src="require('@/assets/image/web/home_cartel/ico_comment.svg')">
			<span class="comment_count size-px-14 font-weight-500 color-333">댓글 <em class="count">{{  bbs_info.comment_tot_count }}</em>개</span>
		</div>
		<div
			v-else-if="mode == 'reply'"
			class="pa-24 flex-row justify-space-between items-center"
		>
			<span class="comment_count">답글쓰기</span>
			<v-icon class="cursor-pointer" @click="cancelReply">mdi-close-circle-outline</v-icon>
		</div>
		<div
			v-else-if="mode == 'modify'"
			class="pa-20 flex-row justify-space-between items-center"
		>
			<span class="comment_count">댓글 수정</span>
			<v-icon class="cursor-pointer" @click="cancelReply">mdi-close-circle-outline</v-icon>
		</div>
		<div
			class="prl-20 wrap-comment flex-1 position-relative"
			style="min-height: 120px; max-height: 480px; overflow: auto"
			ref="body_comment"
		>
			<ul
				ref="post_comment_main_view"
			>
				<li
					v-for="(comment, c_index) in list_comment"
					:key="'comment_' + c_index"
					:class="{ hide: mode == 'reply' && comment.comment_number != item_comment.upper_comment_number && comment.upper_comment_number != item_comment.upper_comment_number, on: mode == 'reply' && comment.comment_number == item_comment.comment_number}"
				>
					<div
						class="post_comment_main_view ptb-20"
					>
						<div
							class="comment_layout"
						>
							<template
								v-if="comment.comment_level == 0"
							>
								<div
									v-if="comment.comment_delete_fg == 'Y'"
									class="comment_view"
								>
									<div class="item_wrap" style="padding: 0 0 0 42px;">
										<div class="write_user">
											<a class="u_profile">
												<img
													:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
												>
											</a>
											<div class="write_desc">
												<strong class="name" style="line-height: 32px;">{{  $language.common.text_delete_comment }}</strong>
											</div>
										</div>
										<div class="comment_body">
											<p style="word-break:break-all; line-height: 140%;"></p>

										</div>
									</div>
								</div>
								<div
									v-else
									class="comment_view"
								>
									<div class="item_wrap" style="padding: 0 0 0 42px;">
										<div class="write_user flex-row justify-space-between items-center">
											<div>
												<a class="u_profile radius-100">
													<img
														:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="사진없음"
														@error="$bus.$emit('onErrorProfileImage', $event)"
														class="width-100"
													>
												</a>
												<div class="write_desc">
													<strong class="name" style="line-height: 32px;"><em v-if="comment.is_block" class="color-red">차단</em> {{  comment.comment_member_nickname }}</strong>
												</div>
											</div>

											<button
												class="post_set"
												@click="onMore(comment, c_index)"
											><span class="hide">설정</span></button>
										</div>
										<div class="comment_body">
											<p :inner-html.prop="comment.comment | nl2br" style="word-break:break-all; line-height: 140%;"></p>
											<div class="position-relative mt-10">
												<img
													v-if="comment.comment_img_url"
													:src="$request.upload_url(comment.comment_img_url)"
													@error="$bus.$emit('onErrorImage', $event)"
													style="max-width: 100%"
												/>
												<v-icon
													v-if="comment.comment_img_url"
													@click="toViewer(comment)"
													class="ml-10 mt-5 position-absolute color-white bg-black pa-3 box"
													style="right: 10px; top: 10px"
												>mdi-arrow-expand-all</v-icon>
											</div>
										</div>
										<div class="write_info">
											<span class="time mr-10">{{  getTimeStory('comment', comment.registdate) }}</span>
											<button
												class="reply"
												@click="setReply(c_index, comment)"
											>답글쓰기</button>
										</div>
									</div>
								</div>
							</template>
							<!-- 재댓글 -->
							<div
								v-else
								class="replylist_view" style="padding: 0 0 0 42px;"
							>
								<div
									class="item_wrap" style="padding: 0 0 0 42px;"
								>
									<template
										v-if="comment.comment_delete_fg == 'Y'"
									>
										<div class="write_user flex-row justify-space-between items-center">
											<a class="u_profile">
												<img
													:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
												>
											</a>
											<div class="write_desc">
												<strong class="name" style="line-height: 32px;">{{  $language.common.text_delete_comment }}</strong>
											</div>
										</div>
									</template>
									<template
										v-else
									>
										<div class="write_user flex-row justify-space-between items-center">
											<div>
												<a class="u_profile">
													<img
														v-if="comment.comment_member_profle_nft_card_img_url"
														:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="사진없음"
													>
													<img
														v-else
														:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
													>
												</a>
												<div class="write_desc">
													<strong class="name" style="line-height: 32px;"><em v-if="comment.is_block" class="state-block">차단</em>  {{  comment.comment_member_nickname }}</strong>
												</div>
											</div>

											<button
												class="post_set"
												@click="onMore(comment, c_index)"
											><span class="hide">설정</span></button>
										</div>
										<div class="comment_body position-relative">
											<button class="hashtag" style="position: absolute; top: 0px; line-height: 140%;">&#64;{{ comment.comment_member_nickname }}</button>
											<p style="line-height: 140%;">&#64;{{ comment.comment_member_nickname}} {{ comment.comment }}</p>
											<div class="position-relative">
												<img
													v-if="comment.comment_img_url"
													:src="$request.upload_url(comment.comment_img_url)"

													style="width: 100%; margin-top: 10px"
												/>
												<v-icon
													v-if="comment.comment_img_url"
													@click="toViewer(comment)"
													class="ml-10 mt-5 position-absolute color-white bg-black pa-3 box"
													style="right: 10px; top: 10px"
												>mdi-arrow-expand-all</v-icon>
											</div>
										</div>
										<div class="write_info">
											<span class="time mr-10">{{  getTimeStory('reply', comment.registdate) }}</span>
											<button
												class="reply"
												@click="setReply(c_index, comment)"
											>답글쓰기</button>
										</div>
									</template>
								</div>
							</div>
							<!-- //재댓글 -->
						</div>
						<!-- 댓글 -->
					</div>
				</li>
			</ul>
			<div
				v-if="items_comment.length < 1"
				class="none"
			>등록된 댓글이 없습니다.</div>
		</div>
		<div
			class=""
			style="position: relative; height: 65px"
		>
			<div
				class="top-line"
				style="position: absolute; left: 0; bottom: 0; width: 100%; background-color: white; border-radius: 0px 0px 20px 20px"
			>
				<div
					v-if="bbs_info.comment_writing_fg != 'N'"
					class=""
				>
					<div
						v-if="item_comment.img_src"
						class="photo_area bg-eee"
					>
						<div class="photo_area_view">
							<img
								v-if="item_comment.img_src"
								:src="$request.upload_url(item_comment.img_src)"
							/>
						</div>
						<button
							@click="item_comment.img_src = ''"
							class="view_close"
						><i class="icon-close"></i></button>
					</div>
				</div>
				<div class="flex-row align-center">
					<div class="flex-column justify-center align-center" style="width:50px; height: 50px;">
						<label
							class="cursor-pointer"
						><input_file type="file" @change="setFile" accept="image/*" class="hide"/><img :src="require('@/assets/image/web/notify_cartel/ico_noti_photo.svg')"/></label>
					</div>
					<div class="flex-1 size-px-14 color-333">
							<textarea
								v-model="item_comment.content"
								placeholder="댓글을 남겨주세요."

								class="scroll-black comment-box"

								@input="reSize"
								style="width: 100%;"
								:style="item_comment.content == '' ? 'height: 20px': 'height: auto'"
								ref="comment_content"
							></textarea>
					</div>
					<div class="flex-column justify-center align-center" style="width:50px; height: 50px;">
						<button
							@click="doPost"
						><img :src="require('@/assets/image/web/notify_cartel/ico_comment_add.svg')"/></button>
					</div>
				</div>
			</div>
		</div>

		<Mafia062
			v-if="is_062"

			:user="user"
			:cartel="cartel_info"
			:bbs="bbs_info"
			:item_comment="item_target"

			@cancel="is_062 = false"
			@click="getBbsComment"
			@modify="onModify"
			@delete="deleteComment"
			style="z-index: 1003"
		></Mafia062>

		<Mafia0632
			v-if="is_0632"

			:user="user"
			:cartel="cartel_info"
			:bbs="bbs_info"
			:comment="item_target"

			@cancel="is_0632 = false"
			@click="update"
			class="layer-popup"
		></Mafia0632>
	</div>
</template>

<script>
import input_file from '@/components/InputFile'
import Mafia062 from "@/view/Cartel/mafia062";
import Mafia0632 from "@/view/Cartel/mafia063-2";
export default {
	name: 'CartelEtcComment'
	, props: ['user', 'cartel_info', 'bbs_info']
	, components: {Mafia0632, Mafia062, input_file }
	, data: function(){
		return {
			mode: 'comment'
			, is_062: false
			, is_0632: false
			, items_comment: []
			, item_search: {
				page_number: 1
				, pagerecnum: 10
			}
			, item_comment: {
				cartel_id: this.$route.params.idx
				, bbs_code: this.$route.params.code
				, bbs_id: this.$route.params.bbs_id
				, user_id: this.user.id
				, user_nick: this.user.nick
				, user_img_src: this.user.img_src
				, upper_comment_number: ''
				, upper_comment_member_nickname: ''
				, content: ''
				, comment_img_url: ''
				, comment_level: 0
				, comment_member_profle_nft_card_img_url: ''
				, comment_number: ''
				, img_src: ''
			}
			, item_target: {}
		}
	}
	, computed: {
		list_comment: function(){
			return this.items_comment.filter(function(item){
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				return item
			})
		}
	}
	, methods: {
		getBbsComment: async function(){
			if(!this.is_scroll) {
				this.is_scroll = true
				try {
					// this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_bbs_comment_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if (result.success) {
						if (this.items_comment.length > 0) {
							this.$log.console('concat')
							this.items_comment = this.items_comment.concat(result.data.comment_list)
						} else {
							this.$log.console('!!concat')
							this.items_comment = result.data.comment_list
						}

						this.item_search.page_number++

						if (result.data.comment_list.length > 0) {
							this.getScroll()
						} else {
							this.removeScroll()
						}
					} else {
						throw result.message
					}
				} catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', {type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
					this.is_scroll = false
				}
			}
		}

		, doPost: function(){
			if(this.mode == 'modify'){
				this.postCommentModify()
			}else{
				this.postComment()
			}
		}

		, postComment: async function(){
			try{
				if(!this.item_comment.content || this.item_comment.content == ''){
					throw '댓글을 입력하세요'
				}
				// this.$bus.$emit('on', true)

				if(await this.postFile()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_comment
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
							, upper_comment_number: this.item_comment.upper_comment_number
							, upper_comment_member_nickname: this.item_comment.upper_comment_member_nickname
							, comment: this.item_comment.content
							, comment_img_url: this.item_comment.comment_img_url
						}
						, type: true
					})

					if(result.success){
						this.item_comment.comment_number = result.data.comment_number
						this.item_comment.registdate = this.$date.getNow('-')
						this.item_comment.comment = this.item_comment.content
						this.item_comment.comment_member_profle_nft_card_img_url = this.user.profle_nft_card_img_url
						this.item_comment.comment_level = this.item_comment.upper_comment_number ? 1 : 0
						this.item_comment.comment_member_nickname = this.user.nickname
						this.item_comment.comment_member_number = this.user.member_number

						await this.appendComment(this.item_comment)
						this.item_comment.content = ''
						this.item_comment = {
							cartel_id: this.$route.params.idx
							, bbs_code: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
							, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.board_number
							, user_id: this.user.id
							, user_nick: this.user.nick
							, user_img_src: this.user.img_src
							, upper_comment_number: ''
							, upper_comment_member_nickname: ''
							, content: ''
							, comment_img_url: ''
						}

						this.mode = 'comment'
						setTimeout(() => {
							this.$refs.body_comment.scrollTop = this.$refs.body_comment.scrollHeight
						}, 100)

						this.$emit('click')
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postCommentModify: async function(){
			try{
				if(!this.item_comment.content || this.item_comment.content == ''){
					throw '댓글을 입력하세요'
				}
				this.$bus.$emit('on', true)
				if(await this.postFile()) {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_comment_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
							, comment_number: this.item_comment.comment_number
							, comment: this.item_comment.content
							, comment_img_url: this.item_comment.comment_img_url
						}
						, type: true
					})

					if (result.success) {
						this.mode = 'comment'
						this.$set(this.items_comment, this.item_target.index, {
							comment_number: this.item_comment.comment_number
							, registdate: this.item_target.registdate
							, comment: this.item_comment.content
							, comment_member_profle_nft_card_img_url: this.user.profle_nft_card_img_url
							, comment_level: this.item_comment.upper_comment_number ? 1 : 0
							, comment_member_nickname: this.user.nickname
							, comment_member_number: this.user.member_number
							, comment_img_url: this.item_comment.img_src
						})

						this.item_comment = {
							cartel_id: this.$route.params.idx
							, bbs_code: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
							, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.board_number
							, user_id: this.user.id
							, user_nick: this.user.nick
							, user_img_src: this.user.img_src
							, upper_comment_number: ''
							, upper_comment_member_nickname: ''
							, content: ''
							, comment_img_url: ''
						}
					}
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postFile: async function(){
			return true
		}
		, getTimeStory: function(type, created_at){
			// this.$log.console(type, created_at)
			return this.$date.getTimeStory(created_at)
		}
		, setFile: function(e){

			this.upload_img = e[0]
			this.$log.console('this.upload_img', this.upload_img)

			this.$set(this.item_comment, 'img_src', e[0])
			this.item_comment.comment_img_url = e[0]

		}
		, scrollListen: async function(){
			if(this.mode == 'reply'){
				return false
			}else{

				let w = this.$refs.body_comment.clientHeight
				let b = this.$refs.post_comment_main_view.scrollHeight
				let t = this.$refs.body_comment.scrollTop

				let max = b - w
				// this.$log.console(w, b, t, max)
				if(max <= 0){
					max = 0
				}

				if(t > (max - 20)){
					await this.getBbsComment()
				}
			}
		}
		, removeScroll: function(){
			this.$log.console('removeScroll')
			if(this.bbs_info.bulletin_number){
				this.$refs.body_comment.removeEventListener('scroll', this.scrollListen)
			}
		}
		, getScroll: function(){
			this.h = this.$refs.post_comment_main_view.scrollHeight
			this.$log.console('getScroll', this.h)
			this.$refs.body_comment.addEventListener('scroll', this.scrollListen);
		}

		,appendComment: function(obj){

			if(obj.upper_comment_number){

				this.items_comment.splice(this.upper_index + 1, 0, obj)

				setTimeout(() => {
					this.$refs.body_comment.scrollTop = this.s_top
				}, 100)
			}else{
				this.items_comment.push(obj)
			}
		}

		, reSize: function(e){
			e.target.style.height = '20px'

			e.target.style.height = (e.target.scrollHeight) + 'px'

			this.$log.console('e.target.innerHeight', e.target.innerHeight)
			if(Number(e.target.style.height.replace('px', '')) >= 120) {
				e.target.style.height = 120 + 'px'
			}else{
				if(e.target.style.height == '40px'){
					if(e.target.value.search(/\n/g) == -1){
						e.target.style.height = '20px'
					}
				}
			}
		}
		, cancelReply: function(){
			this.upper_index = 0
			this.item_comment.comment_number = ''
			this.item_comment.upper_comment_number = ''
			this.item_comment.upper_comment_member_nickname = ''
			this.$set(this.item_comment, 'reply_id', '')
			this.mode = 'comment'

			setTimeout( () => {
				this.$refs.body_comment.scrollTop = this.s_top
			}, 100)

		}
		, setReply: function(index, comment){

			this.s_top = this.$refs.body_comment.scrollTop
			this.upper_index = index
			this.item_comment.comment_number = comment.comment_number
			if(comment.comment_level == 0){
				this.item_comment.upper_comment_number = comment.comment_number
			}else{
				this.item_comment.upper_comment_number = comment.upper_comment_number
			}

			this.item_comment.upper_comment_member_nickname = comment.comment_member_nickname
			this.$set(this.item_comment, 'reply_id', comment.comment_level == 0 ? '' : comment.comment_number)
			this.mode = 'reply'

			this.$log.console(`comment.upper_comment_number :${comment.upper_comment_number}, comment.comment_number: ${comment.comment_number}, this.item_comment.reply_id: ${this.item_comment.reply_id}`)

		}
		, deleteComment: function(){
			this.items_comment.splice(this.item_target.index, 1)
			this.is_062 = false
		}
		, onModify: function(){
			this.is_062 = false
			this.item_comment.content = this.item_target.comment
			this.item_comment.comment_number = this.item_target.comment_number
			this.$set(this.item_comment, 'img_src', this.item_target.comment_img_url)
			this.mode = 'modify'
			this.reSize({ target: this.$refs.comment_content})
		}
		, update: function(item){
			this.$log.console('update', this.item_target, item)

			item.comment = item.content
			this.$set(this.items_comment, this.item_target.index, item)

			this.is_0632 = false
		}
		, toViewer: function(comment){
			this.$bus.$emit('viewer', {
				post_file_url: comment.comment_img_url
			})
		}

		, onMore: function(item, index){
			this.$log.console('longPress', item)
			this.is_062 = true
			this.item_target = item
			this.item_target.index = index
		}
	}
	, created() {
		this.$log.console('cartelEtcComment this.bbs_info ', this.bbs_info)
		this.getBbsComment()
	}
}
</script>