<template>
	<div
		class=" radius-20 mb-20"
	>
		<div
			v-if="is_home"
			@click="toDetail"
		>
			<button
			>
				<img :src="require('@/assets/image/banner/home_banner02_20230601.png')" alt="아트카르텔대항전">
			</button>
		</div>

		<div
			class="mt-10"
			v-if="$route.params.idx == '400894257692612143f5' || $route.params.idx == '373257550565555f12d1'"
			@click="toEvent"
		>
			<div class="cursor-pointer">
				<img :src="require('@/assets/image/event/banner_onus_20230830.png')" class="width-100"/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CartelEtcBanner'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'CARTEL EVENT'
				, title: 'CARTEL EVENT'
			}
		}
	}, computed: {
		is_home: function(){
			let t = false
			let name = this.$route.name
			if(name == 'index' || name == 'main' || name == 'cartel'){
				t = true
			}

			return t
		}
	}
	, methods: {

		toDetail: function(){
			this.$bus.$emit('to', { name: 'EventDetail'})
		}
		, toSubscribe: function(){
			alert('준비중입니다.')
		}
		, toEvent: function(){
			this.$bus.$emit('to', { name: 'EventDetail', params: { idx: 4 }})
		}
	}
	, created() {
	}
}
</script>

<style>
</style>