<template>
	<div
	>
		<button
			@click="onSubscribe"
		>
			<img :src="require('@/assets/image/banner/cartel_home_banner_20230601.png')" alt="아트카르텔대항전">
		</button>

		<!-- 카르텔 구독 플랜 -->
		<CartelSubscribe
			v-if="is_on_subscribe"
			:user="user"
			:cartel_number="bbs_info.cartl_number"
			:plan_info="cartel_info.member_cartl_subscrp_plan_info"

			@cancel="offSubscribe"
		></CartelSubscribe>
	</div>
</template>

<script>
	import CartelSubscribe from "@/view/SubscribeCartel/CartelSubscribe";
	export default {
		name: 'CartelEtcSubscribe'
		, components: {CartelSubscribe}
		, props: ['user', 'bbs_info', 'cartel_info']
		, data: function(){
			return {
				is_on_subscribe: false
			}
		}
		, methods: {
			onSubscribe: function(){
				this.is_on_subscribe = true
			}
			, offSubscribe: function(){
				this.is_on_subscribe = false
			}
		}
	}
</script>