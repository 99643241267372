<template>
	<PopupLayer
		style=" z-index: 9999"
		class="full-height"
	>
		<template
			v-slot:body
		>
			<div
				v-if="is_on_support_result"
				class="width-480 bg-white flex-column justify-space-between pa-20 ma-auto radius-20 position-relative"
				style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div class="full-height flex-column justify-center items-center size-px-14">
					<div class="bg-logo width-100"></div>
					<h4 class="under-line mb-30 pb-30 w-100">불렛 후원 완료</h4>
					<div class="popup-close">
						<img class="cursor-pointer"
							@click="offSupportResult"
							:src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')"
						/>
					</div>

					<div class="width-100 text-center pa-20 size-px-18 bg-heart radius-12 color-red-dc justify-center align-center" style="background-image: none;">
						<img :src="require('@/assets/image/icon_heart.svg')" style="width: 30px; margin-right: 10px;" />
						<b>{{ item_support.spnsh_point | makeComma}}</b> 불렛</div>

					<div class="mt-20"><b>{{ item_cartel.cartl_name }}</b>에게 </div>
					<div class="color-red-dc mt-5"><b>{{ item_support.spnsh_point | makeComma}}개</b>의 <b>불렛</b>을 후원하였습니다.</div>
				</div>
				<div class="mt-30">
					<button
						@click="offSupportResult"
						class="btn-inline btn-primary size-px-14 font-weight-500 radius-20 pa-13-30"
						style="outline: none;"
					>확인</button>
				</div>
			</div>

			<div
				v-else
				class="width-500 radius-20 bg-white  flex-column overflow-hidden ma-auto"
				style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);"
			>
				<div
					class="size-px-20 text-center font-weight-bold under-line pb-20 
					bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20 position-relative"
					>
					<h4 class="color-white">
						불렛 후원하기
					</h4>
					<div class="popup-close">
						<img class="cursor-pointer"
						@click="offSupport"
						:src="require('@/assets/image/web/notify_cartel/ico_close.svg')"/>
					</div>
				</div>

				<div
					class="bg-white pa-30 size-px-14 full-height flex-column overflow-y-auto scroll-black"
				>
					<div>
						<div class="justify-space-between items-center ">
							<div class="flex-row">
								<div class="text-left">
									<div class="flex-row align-center gap-10">
										<span class="size-px-16 font-weight-bold">후원할 불렛 </span>
										<span class="size-px-13"> 보유: {{ item_point.holding_point | makeComma }} 불렛</span>
									</div>
									<div class="size-px-12 color-888">최소 1개부터 최대 50,000개까지 가능</div>
								</div>
							</div>

							<div>
								<button
									@click="toPayment"
									class="btn btn-primary-outline radius-20 width-100 justify-space-between items-center font-weight-500"
									style="padding: 10px 14px 10px 10px;"
								><img :src="require('@/assets/image/icon_heart.svg')" style="width: 20px; vertical-align: middle" class="mr-10" /> 불렛 충전 하기</button>
							</div>
						</div>
						<div class="mt-20">
								<div class="flex-row items-center input-box radius-20">
									<img :src="require('@/assets/image/icon_heart.svg')" style="width: 20px; vertical-align: middle" class="mr-10" />
									<input
										v-model="item_support.spnsh_point"
										type="number"
										class="text-right color-red-dc mr-10 font-weight-700 w-100 size-px-16"
										:rules="$rules.max(item_support, 'point', 5)"
										placeholder="1 ~ 50,000"
									/>
									<span class="color-red-dc font-weight-400 mr-10">불렛</span>
								</div>

							<div class="mt-20 input-box pa-20 color-bbb radius-12">
								<textarea
									v-model="item_support.spnsh_message"
									class="width-100"
									placeholder="후원과 함께 전송할 응원 메시지를 입력하세요."
									rows="5"
									maxlength="2000"
								></textarea>
							</div>
							<div class="mt-10 text-right color-bbb"> {{ item_support.spnsh_message.length }} / 2,000</div>
						</div>
						<div class="mt-10">
							<button
								class="btn-inline btn-primary size-px-14 font-weight-500 radius-20 pa-13-30"
								style="outline: none;"
								@click="onPin"
								:disabled="is_on_confirm"
							>후원하기</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'CartelSupport'
	, components: {PopupLayer}
	, props: ['user', 'item_cartel']
	, data: function(){
		return {
			item_support: {
				spnsh_message: ''
				, spnsh_point: ''
			}
			, is_on_support_result: false
			, item_point: {

			}
			, pinnumber: ''
		}
	}
	, computed: {

		is_on_confirm: function(){
			let t = true
			if(this.item_support.spnsh_point > 0 && this.item_support.spnsh_message){
				t = false
			}
			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_point
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})
				if(result.success){
					this.item_point = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toPayment: function(){
			this.$bus.$emit('to', { name: 'PointList'})
		}
		, offSupport: function(){
			this.$emit('cancel')
		}
		, offSupportResult: function (){
			this.offSupport()
			this.is_on_support_result = false
		}
		, onPin: function(){
			if(!this.item_support.spnsh_point || this.item_support.spnsh_point == 0){
				this.$bus.$emit('notify', { type: 'error', message: '후원할 불렛를 입력하세요'})
			}else{
				this.$bus.$emit('onPin', 'check')

				this.$bus.$off('pinCallback')
				this.$bus.$on('pinCallback', (type, pin)=> {
					// console.log(type, pin)
					this.pinnumber = pin
					this.postCartelSupport()
				})
			}
		}
		, onSupportResult: function(){
			this.is_on_support_result = true
		}
		, postCartelSupport: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_user_support
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, spnsh_point: Number(this.item_support.spnsh_point)
						, spnsh_message: this.item_support.spnsh_message
						, pinnumber: this.pinnumber
					}
					, type: true
				})

				if(result.success){
					this.onSupportResult()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.getData()
	}
}
</script>

<style scoped>

input:focus {outline: none}
input::placeholder{color: #dc505c; font-size:16px; font-weight: 700;}
.input-box:has(input:focus){
	border: 1px solid #dc505c;
}
.color-red input::placeholder{
	color: var(--red)
}
.color-gray textarea::placeholder{
	color: var(--gray01)
}
</style>